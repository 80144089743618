import React from 'react';
import { Container } from '@uc-common/container';
import {
  SolutionsSubHeading,
  SolutionsDescription,
} from '~/src/components/forSolutions/styled-components';
import { Section } from '~/src/components/forMain/Section';
import { CDNMap } from '~/src/components/forMain/LastScreen/CDNMap';
import { ImageCdnScreenGradientSuperHeading } from './styles/ImageCdnScreen.styles';

export const ImageCdnScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <ImageCdnScreenGradientSuperHeading>IMAGE CDN</ImageCdnScreenGradientSuperHeading>
      <SolutionsSubHeading>Secure two-way infrastructure</SolutionsSubHeading>
      <SolutionsDescription>
        Uploadcare rests on a global infrastructure backed by four CDN providers that’s reliable in
        both directions. It ensures every file upload goes through, and lets you securely access
        medical information from anywhere and share patient data under the HIPAA Privacy Rule.
      </SolutionsDescription>

      <CDNMap />
    </Container>
  </Section>
);
