import React from 'react';

import { ServicesInlineLink } from './styles/ServicesScreen.styles';

export const SERVICES = [
  {
    superHeading: 'File uploader',
    heading: 'Accept med-data reliably and securely',
    paragraph:
      'Simplify data collection in healthcare with our leading file uploader. Mobile-friendly and a breeze to use, it accepts all files, with file type filtering, signed uploads and other advanced security features.',
    options: [
      'HTML5 & mobile-ready',
      'Any file type (up to 5TB)',
      'Customizable branding',
      'Encrypted & signed uploads',
    ],
    imageUuid: 'cfc90706-2aae-4b1e-929f-45eae4dd10f3',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'File uploader',
  },
  {
    superHeading: 'Cloud storage',
    heading: 'Store clinical data on a HIPAA-compliant cloud',
    paragraph:
      'Protect electronic medical records and sensitive healthcare data on an enterprise-grade secure cloud storage, backed by HIPAA Business Associate Agreement, a dedicated DevOps team, and the latest encryption.',
    options: [
      'Business Associate Agreement',
      'File management REST API',
      'TLS 1.2+ & SSE-S3 encryption',
      'Backup S3 storage buckets',
    ],
    imageUuid: '1b864832-a4d2-4629-a2b1-41c207bd208b',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Cloud storege',
  },
  {
    superHeading: 'File management',
    heading: 'Manage clinical data without liability risk',
    paragraph: (
      <>
        Avoid common HIPAA violations by design. Create technical safeguards* with credentials for
        healthcare professionals, data flows for sensitive patient data and integrity control for
        Electronic Protected Health Information (ePHI).
        <br />
        <br />* Comply with HIPAA{' '}
        <ServicesInlineLink
          href="https://www.ecfr.gov/current/title-45/subtitle-A/subchapter-C/part-164"
          target="_blank"
        >
          §164.304
        </ServicesInlineLink>
      </>
    ),
    options: [
      'Technical safeguards',
      'Secure auth & access logs',
      'Patient records access control',
      'Connect healthcare database',
    ],
    imageUuid: 'd8d67d8c-eb11-498c-bb4c-1d196eaa6a5a',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Build the perfect med-data pipeline',
  },
];
