import React from 'react';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  FirstScreen,
  HealthcareScreen,
  ServicesScreen,
  ImageCdnScreen,
  HeartbeatScreen,
  PlatformFeaturesScreen,
  LastScreen,
} from '../../components/forHealthcare';

export const frontmatter = {
  title: 'Uploadcare healthcare solutions',
  description:
    'Accelerate digital transformation with Uploadcare’s single-point healthcare data management and HIPAA compliant med-data storage solutions.',
};

const Healthcare = ({ location }) => (
  <SiteLayout meta={{ ...frontmatter }} pathName={location.pathname} isDark>
    <FirstScreen />
    <HealthcareScreen />
    <ServicesScreen />
    <ImageCdnScreen />
    <HeartbeatScreen />
    <PlatformFeaturesScreen />
    <LastScreen />
  </SiteLayout>
);

export default Healthcare;
