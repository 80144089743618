import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';

export const HealthcareScreenSolutionsSubHeading = styled(SolutionsSubHeading)`
  max-width: 900px;
`;

export const HealthcareScreenSection = styled(Wrapper)`
  padding-top: 100px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HealthcareScreenDescription = styled.p(
  ({ theme: { desktop } }) => css`
    font-size: 18px;
    color: #bbc4d2;
    line-height: 1.6;
    text-align: center;
    max-width: 830px;
    margin: 20px auto 0 auto;

    ${desktop()} {
      font-size: 20px;
    }
  `
);

export const HealthcareScreenCardsContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-top: 40px;

    ${tablet()} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${laptop()} {
      grid-gap: 13px;
    }
  `
);
