import React from 'react';

import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';
import { Link } from '~/src/components/Link';
import { SolutionsDescription } from '~/src/components/forSolutions/styled-components';
import {
  HeartbeatScreenSection,
  HeartbeatScreenIntegrationsTitle,
  HeartbeatScreenIntegrations,
  HeartbeatScreenImage,
  HeartbeatScreenHeartbeatText,
  HeartbeatScreenSubHeading,
  HeartbeatScreenButtonsContainer,
  HeartbeatScreenLinkWithArrow,
  HeartbeatScreenButton,
} from './styles/HeartbeatScreen.styles';

export const HeartbeatScreen = () => (
  <HeartbeatScreenSection data-navbar-theme="dark">
    <Container>
      <HeartbeatScreenImage uuid="2a5073da-b7e6-400d-ad5d-1ac55e347a1c" />
      <HeartbeatScreenSubHeading>
        Up and running in a <HeartbeatScreenHeartbeatText>heartbeat</HeartbeatScreenHeartbeatText>
      </HeartbeatScreenSubHeading>
      <SolutionsDescription>
        Integrate Uploadcare quickly and easily, with native libraries for most programming
        languages and no-code platforms. Build innovative healthcare industry solutions, fast, on
        top of compliant infrastructure and modular APIs.
      </SolutionsDescription>

      <HeartbeatScreenButtonsContainer>
        <HeartbeatScreenButton
          forwardedAs={Link}
          to="/accounts/signup/"
          type={ButtonTypeEnum.PRIMARY}
          data-analytics="btn_signup_1"
        >
          Start building for free
        </HeartbeatScreenButton>

        <HeartbeatScreenLinkWithArrow
          to="/docs/integrations/"
          data-analytics="btn_heartbeat_integrations"
        >
          Explore integrations
        </HeartbeatScreenLinkWithArrow>
      </HeartbeatScreenButtonsContainer>
    </Container>

    <HeartbeatScreenIntegrationsTitle>
      Stack agnostic native libraries
    </HeartbeatScreenIntegrationsTitle>
    <HeartbeatScreenIntegrations />
  </HeartbeatScreenSection>
);
