import React from 'react';
import { PlatformFeaturesScreenLinkWithArrow } from './styles/PlatformFeaturesScreen.styles';

export const data = [
  {
    title: 'Secure med-data uploads',
    text: 'Leading HTML5 file uploader with signed uploads, file type filtering and a reliable infrastructure.',
  },
  {
    title: 'HIPAA compliant cloud',
    text: 'Business Associate Agreement ensures HIPAA-compliant healthcare storage solutions.',
  },
  {
    title: 'Clinical data encryption',
    text: (
      <>
        TLS 1.2+ for data in transfer, SSE-S3 for server-side encryption at rest.{' '}
        <PlatformFeaturesScreenLinkWithArrow to="/about/security-whitepaper/">
          Security whitepaper
        </PlatformFeaturesScreenLinkWithArrow>
      </>
    ),
  },
  {
    title: 'Medical records handling',
    text: 'Access medical information from anywhere with CDN signed URLs that prevent unauthorized use.',
  },
  {
    title: 'Guaranteed reliability',
    text: 'Our self-scaled infrastructure is backed with an enterprise-grade SLA and dedicated DevOps team.',
  },
  {
    title: 'Healthcare big-data ready',
    text: 'Fast and secure, our storage and APIs support the bandwidth you need to build big data applications.',
  },
];
