import styled, { css, keyframes } from 'styled-components';
import { Integrations } from '~/src/components/forMain/Uploading/Integrations';
import { Section } from '~/src/components/forMain/Section';
import Image from '@uc-common/image';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { LinkWithArrow } from '~/src/components/Typography';
import { CTAButton } from '~/src/components/forMain/Typography';

export const HeartbeatScreenSection = styled(Section)`
  overflow: hidden;
`;

export const HeartbeatScreenIntegrationsTitle = styled.h5(
  ({ theme: { tablet, laptop } }) => css`
    display: block;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 50px auto 15px;
    color: #838f9a;

    ${tablet()} {
      font-size: 12px;
      margin-top: 70px;
    }

    ${laptop()} {
      font-size: 14px;
      margin-top: 90px;
    }
  `
);

export const HeartbeatScreenIntegrations = styled(Integrations)(
  ({ theme: { tablet } }) => css`
    margin-top: 15px;
    padding-bottom: 30px;

    ${tablet()} {
      padding-bottom: 60px;
      margin-top: 35px;
    }
  `
);

export const HeartbeatScreenQuoteSection = styled.div(
  ({ theme: { laptop } }) => css`
    width: 100%;
    padding: 70px 0;
    background-color: #08080a;
    margin-top: 70px;

    ${laptop()} {
      margin-top: 90px;
    }
  `
);

export const HeartbeatScreenImage = styled(Image)`
  margin: 0 auto 20px;
  display: block;
  min-width: 170px;
  width: 23%;
`;

export const HeartbeatScreenSubHeading = styled(SolutionsSubHeading)`
  display: flex;
  justify-content: center;
  display: inherit;
`;

const pulse = keyframes`
  from {
    color: #FFFFFF;
  }
  to {
    color: #6C6C6C;
  }
`;

export const HeartbeatScreenHeartbeatText = styled.p`
  animation: ${pulse} 1s infinite;
  display: inline;
`;

export const HeartbeatScreenButton = styled(CTAButton)`
  margin-top: 0;
  max-width: 300px;
  width: 100%;
  font-size: 16px;
`;

export const HeartbeatScreenLinkWithArrow = styled(LinkWithArrow)`
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 10px 15px;

  &:hover {
    color: #bfbfbf;
  }
`;

export const HeartbeatScreenButtonsContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: 198px;
    grid-gap: 10px;
    margin-top: 30px;
    align-items: center;
    justify-content: center;

    ${tablet()} {
      grid-template-columns: 198px 198px;
    }
  `
);
