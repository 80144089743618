import styled, { css } from 'styled-components';
import { Advantages } from '~/src/components/forSolutions/Advantages';
import { Link } from '~/src/components/Link';

export const ServicesContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 70px;

    ${tablet()} {
      grid-gap: 100px;
    }

    ${laptop()} {
      grid-gap: 130px;
    }
  `
);

export const ServicesAdvantages = styled(Advantages)`
  margin-top: 0;
`;

export const ServicesInlineLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: border-color 0.1s ease-in;

  &:hover {
    border-color: #596570;
  }
`;
