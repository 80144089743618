import React from 'react';

import { Container } from '@uc-common/container';
import { SmallCard, Quote } from '~/src/components/forSolutions';
import ssgLogo from '../assets/SsgLogo.svg';

import { Section } from '../../forMain/Section';
import {
  HealthcareScreenDescription,
  HealthcareScreenCardsContainer,
  HealthcareScreenSolutionsSubHeading,
} from './styles/HealthcareScreen.styles';

export const HealthcareScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <HealthcareScreenSolutionsSubHeading>
        Bring healthcare innovation to market faster
      </HealthcareScreenSolutionsSubHeading>
      <HealthcareScreenDescription>
        Scale your healthcare data collection and handling pipeline with no liability risk. Focus on
        your business as we keep you secure and compliant.
      </HealthcareScreenDescription>
      <HealthcareScreenCardsContainer>
        <SmallCard
          title="File uploader & upload infrastructure"
          src="https://ucarecdn.com/0e928b99-e406-4f5e-9be4-edb80fe78ee5/"
        />
        <SmallCard
          title="HIPAA & GDPR compliant cloud storage"
          src="https://ucarecdn.com/ad0e1a16-3535-47d2-8efe-cc92ed7e0bc0/"
        />
        <SmallCard
          title="File APIs to build secure access to med-data"
          src="https://ucarecdn.com/cf2c6cc2-e902-496d-8406-fe7a1c305c7f/"
        />
      </HealthcareScreenCardsContainer>
    </Container>

    <Quote
      logoSrc={ssgLogo}
      logoAlt="Special Situations Group logo"
      quoteText="The 'return on investment' of Uploadcare is
      self-evident from day one, and that's just from the time savings.
      Uploadcare has brought file uploading, processing, and delivery to perfection."
      avatarSrc="https://ucarecdn.com/f5bcbf71-40ae-4d88-9d13-4257699b4d7e/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Terence Hong | CEO"
    />
  </Section>
);
