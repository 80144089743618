import React from 'react';
import { Container } from '@uc-common/container';
import { Quote, FeatureCard } from '~/src/components/forSolutions';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import {
  FeaturesScreenIntegrationSection,
  FeaturesScreenIntegrationContainer,
  FeaturesScreenIntegrationTitle,
  FeaturesScreenIntegrationParagraph,
  FeaturesScreenIntegrationLogo,
  FeaturesScreenArrowIcon,
  FeaturesScreenArrowButton,
  FeaturesScreenArrowButtonText,
} from '../../forELearning/FeaturesScreen/styles/FeaturesScreen.styles';
import {
  PlatformFeaturesScreenSection,
  PlatformFeaturesScreenGradientSuperHeading,
  PlatformFeaturesScreenFeaturesGrid,
  PlatformFeaturesScreenApiExample,
} from './styles/PlatformFeaturesScreen.styles';
import { data } from './data';

export const PlatformFeaturesScreen = () => (
  <PlatformFeaturesScreenSection data-navbar-theme="dark">
    <PlatformFeaturesScreenApiExample />
    <Container>
      <PlatformFeaturesScreenGradientSuperHeading>
        Platform features for Healthcare
      </PlatformFeaturesScreenGradientSuperHeading>
      <SolutionsSubHeading>Accelerate your healthcare digital transformation</SolutionsSubHeading>

      <PlatformFeaturesScreenFeaturesGrid>
        {data.map(({ title, text }) => (
          <FeatureCard key={title} title={title} text={text} />
        ))}
      </PlatformFeaturesScreenFeaturesGrid>
    </Container>

    <FeaturesScreenIntegrationSection>
      <FeaturesScreenIntegrationContainer>
        <FeaturesScreenIntegrationLogo
          src={logos[BuzzLogoIdEnum.SUPERVISION_ASSIST].src}
          alt="Supervision assist logo"
        />
        <FeaturesScreenIntegrationTitle>
          Saving 50 hours a year with HIPAA-compliant counseling video uploads
        </FeaturesScreenIntegrationTitle>
        <FeaturesScreenIntegrationParagraph>
          SupervisionAssist helps university coordinators manage students’ training programs in
          behavioral health. With Uploadcare, they built a secure and HIPAA-compliant solution that
          handles both sensitive patient information and training videos.
        </FeaturesScreenIntegrationParagraph>
        <FeaturesScreenArrowButton
          to="/customers/supervisionassist/"
          data-analytics="btn_supervision-assist"
        >
          <FeaturesScreenArrowButtonText>Read more</FeaturesScreenArrowButtonText>
          <FeaturesScreenArrowIcon />
        </FeaturesScreenArrowButton>
      </FeaturesScreenIntegrationContainer>
    </FeaturesScreenIntegrationSection>

    <Quote
      logoSrc={logos[BuzzLogoIdEnum.CHATRA].src}
      logoAlt="Chatra logo"
      quoteText="In-house file infrastructure? No thank you. With Uploadcare, we save 40 hours per week and
      decrease the total cost of ownership (TCO) on infrastructure development. More to ask?"
      avatarSrc="https://ucarecdn.com/8285d910-1569-4588-b9e1-4bea2c85aa4a/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Yakov Karda | CEO & Co-founder"
    />
  </PlatformFeaturesScreenSection>
);
