import styled, { css } from 'styled-components';
import { GradientSuperHeading, LinkWithArrow } from '~/src/components/Typography';
import { Section } from '~/src/components/forMain/Section';
import { ApiExample } from '~/src/components/forMain/Platform/ApiExample';

export const PlatformFeaturesScreenSection = styled(Section)`
  padding-top: 0;
`;

export const PlatformFeaturesScreenGradientSuperHeading = styled(GradientSuperHeading)`
  align-self: center;
  display: block;

  background: radial-gradient(
    141.01% 172.5% at 0% 0%,
    rgb(73, 36, 177) 0%,
    rgb(92, 161, 255) 84.45%
  );
`;

export const PlatformFeaturesScreenFeaturesGrid = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 20px;
    margin-top: 50px;

    ${tablet()} {
      grid-template-columns: 1fr 1fr;
      margin-top: 70px;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

export const PlatformFeaturesScreenApiExample = styled(ApiExample)`
  margin-bottom: 20px;
`;

export const PlatformFeaturesScreenLinkWithArrow = styled(LinkWithArrow)`
  color: #bbc4d2;
  text-decoration: underline;

  &:hover {
    color: #ffffff;
  }
`;
